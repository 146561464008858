// A wrapper around the generated API to provide a more convenient interface.
// The general pattern is to map the generated API endpoints and types to a more convenient type
// that is easier to work with in the rest of the application.
//
// For example, the generated API returns a string for the date of birth of a
// patient. This wrapper converts that string to a Date object so that the
// rest of the application doesn't have to deal with parsing the string.
//
// This API then exports the types and functions that the rest of the
// application should use.

import { zonedTimeToUtc } from "date-fns-tz";
import {
  addDays,
  format,
  getDay,
  isValid,
  parse,
  set,
  startOfToday,
} from "date-fns";

// Please keep alphasorted
import {
  AIFeedbackApi,
  APIAppointmentStatus,
  APIAppointmentType,
  APIRecurrenceType,
  AppointmentAddToCalendarDetailsResponse,
  AppointmentApi,
  AppointmentResponse as _AppointmentResponse,
  BookAppointmentPayload as _BookAppointmentPayload,
  BookAppointmentResponse as _BookAppointmentResponse,
  CalendarEventResponse as _CalendarEventResponse,
  CalendarEventsResponse,
  CardSource,
  ChargeStatus,
  ChatApi,
  CompletePartnerBookingPayload,
  Configuration,
  CreateCalendarBlockPayload as _CreateCalendarBlockPayload,
  CreateJournalEntryPayload,
  CreateNutritionResearchThreadResponse,
  CursorPaginatedJournalEntriesResponse as _CursorPaginatedJournalEntriesResponse,
  DefaultApi,
  ErrorResponse,
  ExternalProviderResponseList,
  ExternalProviderType,
  Gender,
  GetNutritionResearchThreadMessagesResponse,
  GetNutritionResearchThreadResponse,
  HeightFieldOutput,
  HeightUnits,
  InsuranceCompany,
  IntakeFormResponse,
  JournalEntryResponse as _JournalEntryResponse,
  JournalStreakResponse,
  LoginResponse as _LoginResponse,
  MealPlanCondition,
  MealPlanningApi,
  MealPlanResponse as MealPlan,
  MealResponse as Meal,
  NutritionResearchApi,
  NutritionResearchFeedbackRating,
  NutritionResearchMessageDetails,
  NutritionResearchRunThreadResponse,
  PartnerBookingApi,
  PatientApi,
  PatientBillingApi,
  PatientBillingItemCoverageStatus,
  PatientBillingItemDetailsResponse,
  PatientConfirmationByCodePayload,
  PatientConfirmationPayload,
  PatientDetailsInclusions,
  PatientDetailsListResponse,
  PatientDetailsResponse,
  PatientDiscoverySource,
  PatientLabResponse,
  PatientLabsResponse,
  PatientRecapApi,
  PatientRecapResponse as PatientRecap,
  PayoutApi,
  ProviderApi,
  ProviderAvailabilityWindowResponse as _ProviderAvailabilityWindowResponse,
  ProviderDetailsResponse,
  ProviderFeedbackPayload,
  ProviderForPatientDetailsResponse,
  RecipeResponse as Recipe,
  ReleaseOfInformationPayload,
  ResetPasswordResponse as _ResetPasswordResponse,
  SetupPatientAndBookAppointmentPayload,
  SetupPatientAndBookAppointmentResponse,
  Sex,
  UpcomingAppointmentSummary as _UpcomingAppointmentSummary,
  UpdateAppointmentPayload as _UpdateAppointmentPayload,
  UpdateCalendarEventPayload as _UpdateCalendarEventPayload,
  UpdateIntakePayload,
  UpdateJournalEntryPayload,
  UpdateMealPayload,
  UpdatePatientPayload,
  UpdateProviderAvailabilitySchedulePayload as _UpdateProviderAvailabilitySchedulePayload,
  UpdateProviderAvailabilityScheduleWindowPayload as _UpdateProviderAvailabilityScheduleWindowPayload,
  UpdateProviderPayload,
  UploadPatientLabPayload,
  USState,
  ValidationErrorResponse,
  VideoApi,
  WeightFieldOutput,
  WeightUnits,
  AuthApi,
  UpdateIdentityResponse as _UpdateIdentityResponse,
  AppointmentLocationType,
  ProviderAvailabilityType,
  AvailabilitySlot,
} from "./generated";
import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosError } from "axios"; // eslint-disable-line @typescript-eslint/no-unused-vars

// Please keep alphasorted
export {
  APIAppointmentStatus,
  APIAppointmentType,
  APIRecurrenceType,
  ChargeStatus,
  Gender,
  InsuranceCompany,
  PatientBillingItemCoverageStatus,
  Sex,
};

export type { MealPlan, Meal, Recipe };

// Please keep alphasorted
export type {
  ErrorResponse,
  ExternalProviderResponse,
  IntakeFormResponse,
  JournalStreakResponse as JournalStreak,
  MealPlanCondition,
  OnboardableProviderResponse as OnboardableProvider,
  PatientAddress,
  PatientBillingItemDetailsResponse,
  PatientRecapResponse as PatientRecap,
  PaymentInstrumentResponse,
  ProviderDetailsResponse,
  ProviderRateDetail as ProviderRate,
  ProviderRatesResponse as ProviderRates,
  ProviderReviewResponse,
  UpdatePatientPayload,
  UpdateProviderPayload,
} from "./generated";

export { CardBrand, CardSource } from "./generated";

export const URL = process.env.REACT_APP_FAY_API_BASE_PATH;

const axiosInstance = axios.create();

const checkIsErrorResponse = (data: any): data is ErrorResponse => {
  return data?.detail !== undefined;
};

const checkIsValidationErrorResponse = (
  data: any,
): data is ValidationErrorResponse => {
  return data?.detail !== undefined && Array.isArray(data.detail);
};

const USER_FRIENDLY_ERROR_STATUSES = [400, 404, 409];

// Add a 400 response interceptor to throw a UserFriendlyError
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error instanceof AxiosError &&
      !!error.response &&
      USER_FRIENDLY_ERROR_STATUSES.includes(error.response.status) &&
      checkIsErrorResponse(error.response.data)
    ) {
      throw new UserFriendlyError({
        response: error.response.data,
      });
    }
    throw error;
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error instanceof AxiosError &&
      !!error.response &&
      error.response.status === 422 &&
      checkIsValidationErrorResponse(error.response.data)
    ) {
      throw new ValidationError({
        response: error.response.data,
      });
    }
    throw error;
  },
);

const configuration = new Configuration({
  basePath: URL,
  accessToken: async () => {
    const session = await fetchAuthSession();
    const { idToken } = session.tokens ?? {};
    const rawToken = idToken?.toString() ?? "";
    const tokenPrefix = {
      provider: "provider",
      client: "patient",
    }[process.env.REACT_APP_FAY_APP ?? ""];

    if (!tokenPrefix) {
      throw new Error("Invalid app configuration");
    }

    return `${tokenPrefix}::::${rawToken}`;
  },
  baseOptions: {
    headers:
      process.env.NODE_ENV === "development"
        ? {
            "ngrok-skip-browser-warning": `TRUE`,
          }
        : {},
  },
});

const appointmentApi = new AppointmentApi(
  configuration,
  undefined,
  axiosInstance,
);
const mealPlanApi = new MealPlanningApi(
  configuration,
  undefined,
  axiosInstance,
);
const chatApi = new ChatApi(configuration, undefined, axiosInstance);
const patientRecapApi = new PatientRecapApi(
  configuration,
  undefined,
  axiosInstance,
);
const patientApi = new PatientApi(configuration, undefined, axiosInstance);
const patientBillingApi = new PatientBillingApi(
  configuration,
  undefined,
  axiosInstance,
);
const payoutApi = new PayoutApi(configuration, undefined, axiosInstance);
const providerApi = new ProviderApi(configuration, undefined, axiosInstance);
const aiFeedbackApi = new AIFeedbackApi(
  configuration,
  undefined,
  axiosInstance,
);
const videoCallApi = new VideoApi(configuration, undefined, axiosInstance);
const defaultApi = new DefaultApi(configuration, undefined, axiosInstance);
const nutritionResearchApi = new NutritionResearchApi(
  configuration,
  undefined,
  axiosInstance,
);
const partnerBookingApi = new PartnerBookingApi(
  configuration,
  undefined,
  axiosInstance,
);
const authApi = new AuthApi(configuration, undefined, axiosInstance);

// Modifies the type T with the properties of type R.
// Only modifies the properties that are common to both T and R.
// If R includes a key that does not exist in T, then that key is ignored.
// This prevents hidden backwards compatibility issues when modifying the API.
type Modify<T, R> = {
  [P in keyof T]: P extends keyof R ? R[P] : T[P];
};

export class UserFriendlyError extends Error {
  response: ErrorResponse;

  constructor({ response }: { response: ErrorResponse }) {
    super();
    this.response = response;
  }
}

export class ValidationError extends Error {
  response: ValidationErrorResponse;

  constructor({ response }: { response: ValidationErrorResponse }) {
    super();
    this.response = response;
  }
}

export const formatHeight = (height: HeightFieldOutput): string => {
  let inchesTotal: number;

  if (height.unit === HeightUnits.Centimeters) {
    // Convert centimeters to inches
    inchesTotal = parseFloat(height.value) / 2.54;
  } else if (height.unit === HeightUnits.Inches) {
    inchesTotal = parseFloat(height.value);
  } else {
    throw new Error(`Unknown height unit: ${height.unit}`);
  }

  // Convert total inches to feet and inches
  const feet = Math.floor(inchesTotal / 12);
  const inches = Math.round(inchesTotal % 12);

  return `${feet}' ${inches}"`;
};

export const formatWeight = (weight: WeightFieldOutput): string => {
  let pounds: number;

  if (weight.unit === WeightUnits.Kilograms) {
    // Convert kilograms to pounds
    pounds = parseFloat(weight.value) * 2.20462;
  } else if (weight.unit === WeightUnits.Pounds) {
    pounds = parseFloat(weight.value);
  } else {
    throw new Error(`Unknown weight unit: ${weight.unit}`);
  }

  return `${pounds.toFixed(1)} lbs`;
};

export type GetProviderPatientsResponse = Modify<
  PatientDetailsListResponse,
  {
    patients: PatientDetails[];
  }
>;

export type UpcomingAppointmentSummary = Modify<
  _UpcomingAppointmentSummary,
  {
    start_time: Date;
  }
>;

export type PatientDetails = Modify<
  PatientDetailsResponse,
  {
    patient_id: string;
    cognito_id: string;
    first_name: string;
    preferred_first_name: string;
    last_name: string;
    email: string;
    date_of_birth?: Date | null;
    joined_at: Date | null;
    avatar_url: string | null;
    next_appointment?: UpcomingAppointmentSummary | null;
    external_providers_list: ExternalProviderResponseList | null;
    has_completed_intake: boolean | null;
  }
>;

const _mapUpcomingAppointmentSummary = (
  appointmentSummary?: _UpcomingAppointmentSummary | null,
): UpcomingAppointmentSummary | undefined => {
  if (appointmentSummary) {
    return {
      ...appointmentSummary,
      start_time: convertDateTimeStringToDateTime(
        appointmentSummary.start_time,
      ),
    };
  }
};

const _mapPatientDetailsResponse = (
  patient: PatientDetailsResponse,
): PatientDetails => ({
  ...patient,
  joined_at: patient.joined_at
    ? convertDateTimeStringToDateTime(patient.joined_at)
    : null,
  date_of_birth: patient.date_of_birth
    ? convertDateStringToDateTime(patient.date_of_birth)
    : null,
  next_appointment: _mapUpcomingAppointmentSummary(patient.next_appointment),
});

export const getProviderPatients = async ({
  withOldPatients,
}: {
  withOldPatients?: boolean;
}) => {
  const options = [
    PatientDetailsInclusions.NextAppointment,
    ...(withOldPatients ? [PatientDetailsInclusions.OldPatients] : []),
  ];

  const response = await patientApi.getPatientsForProviderPatientsGet(options);

  const serializedPatients = response.data.patients.map(
    _mapPatientDetailsResponse,
  );

  return {
    ...response.data,
    patients: serializedPatients,
  };
};

export const getPatientDetailsById = async (
  patientId: string,
): Promise<PatientDetails> => {
  const response =
    await patientApi.getPatientDetailsPatientsPatientIdGet(patientId);

  return _mapPatientDetailsResponse(response.data);
};

export const updatePatientById = async (
  patientId: string,
  payload: UpdatePatientPayload,
): Promise<PatientDetails> => {
  const response = await patientApi.updatePatientPatientsPatientIdPatch(
    patientId,
    payload,
  );

  return _mapPatientDetailsResponse(response.data);
};

export const getPatientIntakeFormAnswers = async (
  patientId: string,
): Promise<IntakeFormResponse> => {
  const response =
    await patientApi.getPatientIntakeFormAnswersPatientsPatientIdIntakeGet(
      patientId,
    );

  return response.data;
};

export type CalendarEvents = Modify<
  CalendarEventsResponse,
  {
    events: CalendarEvent[];
  }
>;

export type CalendarEvent = Modify<
  _CalendarEventResponse,
  { start: Date; end: Date }
>;

const convertDateStringToDateTime = (dateString: string): Date => {
  return parse(dateString, "yyyy-MM-dd", new Date());
};
const convertDateTimeStringToDateTime = (dateString: string): Date => {
  return new Date(dateString);
};

const _mapCalendarEventResponse = (
  calendarEvent: _CalendarEventResponse,
): CalendarEvent => ({
  ...calendarEvent,
  start: convertDateTimeStringToDateTime(calendarEvent.start),
  end: convertDateTimeStringToDateTime(calendarEvent.end),
});

export const getCalendarEventsWithinTimeRange = async (
  startTime: Date,
  endTime: Date,
): Promise<CalendarEvents> => {
  const response = await appointmentApi.getCalendarEventsCalendarEventsGet(
    startTime.toISOString(),
    endTime.toISOString(),
  );

  const serializedEvents = response.data.events.map(_mapCalendarEventResponse);

  return {
    ...response.data,
    events: serializedEvents,
  };
};

export const getCalendarEventById = async (
  calendarEventId: string,
): Promise<CalendarEvent> => {
  const response =
    await appointmentApi.getCalendarEventCalendarEventsCalendarEventIdGet(
      calendarEventId,
    );

  return {
    ...response.data,
    start: convertDateTimeStringToDateTime(response.data.start),
    end: convertDateTimeStringToDateTime(response.data.end),
  };
};

export type { AppointmentDetailsResponse } from "./generated";

export type CreateCalendarBlockPayload = Modify<
  _CreateCalendarBlockPayload,
  {
    start: Date;
    end: Date;
  }
>;

const _mapCreateCalendarBlockPayload = (
  payload: CreateCalendarBlockPayload,
): _CreateCalendarBlockPayload => ({
  ...payload,
  start: payload.start.toISOString(),
  end: payload.end.toISOString(),
});

export const createCalendarBlock = async (
  payload: CreateCalendarBlockPayload,
): Promise<CalendarEvent> => {
  const response =
    await appointmentApi.createCalendarBlockCalendarEventsBlocksPost(
      _mapCreateCalendarBlockPayload(payload),
    );

  return _mapCalendarEventResponse(response.data);
};

export type UpdateCalendarEventPayload = Modify<
  _UpdateCalendarEventPayload,
  {
    start: Date;
    end: Date;
  }
>;

export const deleteCalendarEvent = async (
  calendarEventId: string,
  { withSubsequentRecurringEvents }: { withSubsequentRecurringEvents: boolean },
): Promise<void> => {
  await appointmentApi.deleteCalendarEventCalendarEventsCalendarEventIdDelete(
    calendarEventId,
    withSubsequentRecurringEvents,
  );
};

export type BookAppointmentPayload = Modify<
  _BookAppointmentPayload,
  {
    start: Date;
    location_type: AppointmentLocationType;
  }
>;

const _mapBookAppointmentPayload = (
  payload: BookAppointmentPayload,
): _BookAppointmentPayload => ({
  ...payload,
  start: payload.start.toISOString(),
});

export type BookAppointmentResponse = Modify<_BookAppointmentResponse, {}>;

const _mapBookAppointmentResponse = (
  response: _BookAppointmentResponse,
): BookAppointmentResponse => ({
  ...response,
});

export const bookAppointment = async (
  payload: BookAppointmentPayload,
): Promise<BookAppointmentResponse> => {
  const response = await appointmentApi.bookAppointmentAppointmentsPost(
    _mapBookAppointmentPayload(payload),
  );
  return _mapBookAppointmentResponse(response.data);
};

export const setupPatientAndBookAppointment = async (
  payload: SetupPatientAndBookAppointmentPayload,
): Promise<SetupPatientAndBookAppointmentResponse> => {
  const response =
    await appointmentApi.setupPatientAndBookAppointmentAppointmentsSetupPatientPost(
      payload,
    );
  return response.data;
};

export type UpdateAppointmentPayload = Modify<
  _UpdateAppointmentPayload,
  {
    start?: Date;
  }
>;

const _mapUpdateAppointmentPayload = (
  payload: UpdateAppointmentPayload,
): _UpdateAppointmentPayload => ({
  ...payload,
  ...{
    start: payload.start?.toISOString(),
  },
});

export const updateAppointment = async (
  appointmentId: string,
  payload: UpdateAppointmentPayload,
): Promise<AppointmentResponse> => {
  const response =
    await appointmentApi.updateAppointmentAppointmentsAppointmentIdPatch(
      appointmentId,
      _mapUpdateAppointmentPayload(payload),
    );

  return _mapAppointmentResponse(response.data);
};

export const cancelAppointment = async (
  appointmentId: string,
): Promise<void> => {
  await appointmentApi.cancelAppointmentAppointmentAppointmentIdCancelPost(
    appointmentId,
  );
};

// A time string has a format of HH:MM:SS
export type TimeString = string & { __timeString: never };

export const timeStringFormat = "HH:mm:ss";

export const isTimeString = (timeString: string): timeString is TimeString => {
  const parsedDate = parse(timeString, timeStringFormat, new Date());
  return (
    isValid(parsedDate) && timeString === format(parsedDate, timeStringFormat)
  );
};

export const createDateFromAvailability = (
  timeString: TimeString,
  dayOfWeek: number,
  timezone: string,
): Date => {
  // Standardize to today's date
  let baseDate = startOfToday(); // Ensures all dates are on the same day
  const currentDayOfWeek = getDay(baseDate); // 0 (Sunday) to 6 (Saturday)

  // Adjust baseDate to the desired day_of_week (1=Monday, ...,7=Sunday)
  const desiredDayOffset = (dayOfWeek % 7) - currentDayOfWeek;
  baseDate = addDays(baseDate, desiredDayOffset);

  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const zonedDate = set(baseDate, { hours, minutes, seconds, milliseconds: 0 });

  return zonedTimeToUtc(zonedDate, timezone);
};

export const createTimeStringFromDate = (date: Date): TimeString => {
  const timeString = format(date, timeStringFormat);

  if (!isTimeString(timeString)) {
    throw new Error(
      "Error converting date to time string: invalid time string",
    );
  }
  return timeString;
};

export type ProviderAvailabilityWindow = Modify<
  _ProviderAvailabilityWindowResponse,
  {
    start_time: Date;
    end_time: Date;
    day_of_week: DayOfWeek;
    availability_type: ProviderAvailabilityType;
  }
>;

const _mapProviderAvailabilityWindow = (
  response: _ProviderAvailabilityWindowResponse,
): ProviderAvailabilityWindow => {
  const { day_of_week, start_time, end_time, timezone } = response;

  if (!isTimeString(start_time) || !isTimeString(end_time)) {
    throw new Error(
      "Error fetching provider availability: invalid time string",
    );
  }

  return {
    ...response,
    start_time: createDateFromAvailability(start_time, day_of_week, timezone),
    end_time: createDateFromAvailability(end_time, day_of_week, timezone),
  };
};

export const getAvailability = async (): Promise<
  ProviderAvailabilityWindow[]
> => {
  const response =
    await appointmentApi.getProviderAvailabilityScheduleAvailabilityScheduleGet();

  return response.data.windows.map(_mapProviderAvailabilityWindow);
};

// Update Provider Availability Schedule
// Using date-fns:
// const monday = addDays(startOfISOWeek(newDate()), 1);
// format(monday, "EEEE") => "Monday"
// format(monday, "EE") => "Mon"
export type DayOfWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type UpdateProviderAvailabilityScheduleWindowPayload = Modify<
  _UpdateProviderAvailabilityScheduleWindowPayload,
  {
    day_of_week: DayOfWeek;
    start_time: TimeString;
    end_time: TimeString;
  }
>;

export type UpdateProviderAvailabilitySchedulePayload = Modify<
  _UpdateProviderAvailabilitySchedulePayload,
  {
    windows: UpdateProviderAvailabilityScheduleWindowPayload[];
  }
>;

const _mapUpdateProviderAvailabilitySchedulePayload = (
  payload: UpdateProviderAvailabilitySchedulePayload,
): _UpdateProviderAvailabilitySchedulePayload => ({
  ...payload,
  windows: payload.windows.map((availabilityWindow) => ({
    ...availabilityWindow,
    start_time: availabilityWindow.start_time,
    end_time: availabilityWindow.end_time,
    day_of_week: availabilityWindow.day_of_week,
  })),
});

export const updateAvailabilitySchedule = async (
  payload: UpdateProviderAvailabilitySchedulePayload,
): Promise<ProviderAvailabilityWindow[]> => {
  const response =
    await appointmentApi.setProviderAvailabilityScheduleAvailabilitySchedulePost(
      _mapUpdateProviderAvailabilitySchedulePayload(payload),
    );

  return response.data.windows.map(_mapProviderAvailabilityWindow);
};

export const getAvailabilitiesForProviderId = async (data: {
  startTime: Date;
  endTime: Date;
  providerId: string;
  appointmentDurationInMinutes: number;
}): Promise<AvailabilitySlot[]> => {
  const response =
    await appointmentApi.getProviderAvailableSlotsAvailabilitySlotsGet(
      data.startTime.toISOString(),
      data.endTime.toISOString(),
      data.appointmentDurationInMinutes,
      data.providerId,
    );
  return response.data.available_slots;
};

export const getProviderDetailsById = async (providerId: string) => {
  const response =
    await providerApi.getProviderDetailsProvidersProviderIdGet(providerId);

  return response.data;
};

export const getBasicProviderDetailsById = async (providerId: string) => {
  const response =
    await providerApi.getProviderDetailsBasicProvidersProviderIdBasicGet(
      providerId,
    );

  return response.data;
};

export const updateSelfProvider = async (
  payload: UpdateProviderPayload,
): Promise<ProviderDetailsResponse> => {
  const response = await providerApi.updateProviderProvidersProviderIdPatch(
    "me",
    payload,
  );

  return response.data;
};

export type AppointmentResponse = Modify<
  _AppointmentResponse,
  {
    start: Date;
    end: Date;
    chart_notes_last_updated_at: Date | null;
    location_type: AppointmentLocationType;
    location: string | null;
  }
>;

const _mapAppointmentResponse = (
  appointment: _AppointmentResponse,
): AppointmentResponse => ({
  ...appointment,
  start: convertDateTimeStringToDateTime(appointment.start),
  end: convertDateTimeStringToDateTime(appointment.end),
  chart_notes_last_updated_at: appointment.chart_notes_last_updated_at
    ? convertDateTimeStringToDateTime(appointment.chart_notes_last_updated_at)
    : null,
});

const getAppointments = async (
  patientId?: string | null,
  providerId?: string | null,
  beforeStart: Date | null = null,
  afterStart: Date | null = null,
  beforeEnd: Date | null = null,
  afterEnd: Date | null = null,
  sort: "asc" | "desc" | null = null,
  limit: number = 100,
  offset: number = 0,
) => {
  const response = await appointmentApi.getAppointmentsAppointmentsGet(
    providerId,
    patientId,
    null,
    null,
    beforeStart?.toISOString() ?? null,
    afterStart?.toISOString() ?? null,
    beforeEnd?.toISOString() ?? null,
    afterEnd?.toISOString() ?? null,
    sort,
    limit,
    offset,
  );

  return response.data.map((appointment) =>
    _mapAppointmentResponse(appointment),
  );
};

export const getAppointmentDetailsById = async (
  appointmentId: string,
): Promise<AppointmentResponse> => {
  const response =
    await appointmentApi.getAppointmentAppointmentsAppointmentIdGet(
      appointmentId,
    );

  return _mapAppointmentResponse(response.data);
};

interface GetProviderPatientAppointmentsOptions {
  providerId?: string | null;
  patientId?: string | null;
  startBefore?: Date | null;
  startAfter?: Date | null;
  endBefore?: Date | null;
  endAfter?: Date | null;
  sort?: "asc" | "desc" | null;
  limit?: number;
  offset?: number;
}

export const getProviderPatientAppointments = async ({
  providerId,
  patientId,
  startBefore,
  startAfter,
  endBefore,
  endAfter,
  sort,
  limit,
  offset,
}: GetProviderPatientAppointmentsOptions) =>
  getAppointments(
    patientId,
    providerId,
    startBefore,
    startAfter,
    endBefore,
    endAfter,
    sort,
    limit,
    offset,
  );

export const registerProvider = async (
  firstName: string,
  lastName: string,
  email: string,
  npi: string,
  timezone: string,
  isAdmin: boolean = false,
  isTest: boolean = false,
  cognitoId: string | undefined = undefined,
) => {
  const response = await providerApi.registerProviderProvidersPost({
    first_name: firstName,
    last_name: lastName,
    email: email,
    npi: npi,
    timezone: timezone,
    is_admin: isAdmin,
    is_test: isTest,
    cognito_id: cognitoId,
  });

  return response.data;
};

export const getProvidersAdmin = async (
  limit: number = 100,
  offset: number = 0,
) => {
  const response = await providerApi.getProvidersAdminAdminProvidersGet(
    limit,
    offset,
  );

  return response.data;
};

export const getOnboardableProvidersAdmin = async () => {
  const response =
    await providerApi.allOnboardableProvidersAdminOnboardableProvidersGet();

  return response.data;
};

export const getProviderIdentityDetails = async (providerId: string) => {
  const response =
    await providerApi.getProviderIdentityProvidersProviderIdIdentityGet(
      providerId,
    );

  return response.data;
};

export const resetProviderPassword = async (providerId: string) => {
  await providerApi.resetProviderPasswordProvidersProviderIdPasswordResetPost(
    providerId,
  );
};

export const registerPatient = async (
  email: string,
  firstName: string,
  lastName: string,
  dateOfBirth: Date,
  timezone: string,
) => {
  const response = await patientApi.registerPatientAdminPatientsPost({
    email,
    first_name: firstName,
    last_name: lastName,
    date_of_birth: format(dateOfBirth, "yyyy-MM-dd"),
    timezone,
    discovery_source: PatientDiscoverySource.Other,
  });

  return response.data;
};

export const submitBookingEmail = async (email: string, providerId: string) => {
  const response =
    await patientApi.submitBookingEmailPatientsBookingSubmitEmailPost({
      email,
      provider_id: providerId,
    });

  return response.data;
};

export const getMePaymentInstrument = async () => {
  const response =
    await patientBillingApi.getPatientPaymentInstrumentPatientsPatientIdPaymentGet(
      "me",
      {
        validateStatus: (status) => status === 200 || status === 404,
        "axios-retry": {
          retries: 0,
        },
      },
    );

  // 404 is acceptable here, we just return null.
  if (response.status === 404) {
    return null;
  }

  if (response.status !== 200) {
    throw new Error(
      `Error fetching patient payment instrument: ${response.statusText}`,
    );
  }

  return response.data;
};

export const updatePaymentInstrument = async (
  cardToken: string,
  cardSource: CardSource,
) => {
  const response =
    await patientBillingApi.createPatientPaymentInstrumentPatientsPatientIdPaymentPost(
      "me",
      {
        card_token: cardToken,
        card_source: cardSource,
      },
    );

  return response.data;
};

export const getPatientProviders = async (): Promise<
  ProviderForPatientDetailsResponse[]
> => {
  const response = await providerApi.getProvidersForPatientProvidersGet();

  if (response.status !== 200) {
    throw new Error(`Error fetching patient providers: ${response.statusText}`);
  }

  return response.data;
};

type ProviderConfirmationPayload = {
  new_password: string;
};

export type ConfirmationPayload =
  | PatientConfirmationPayload
  | ProviderConfirmationPayload;

export type ConfirmationStatus = {
  tokenValid: boolean;
  canConfirm: boolean;
  email: string | null;
  cognitoId: string | null;
};

export const getPatientConfirmationPreflight = async (
  jwt: string,
): Promise<ConfirmationStatus> => {
  const response =
    await patientApi.getPatientConfirmationStatusPatientConfirmGet({
      headers: { Authorization: `Bearer ${jwt}` },
      validateStatus: (status) =>
        status === 200 || status === 404 || status === 401,
    });

  if (response.status === 401 || response.status === 404) {
    return {
      tokenValid: false,
      canConfirm: false,
      email: null,
      cognitoId: null,
    };
  }

  return {
    tokenValid: true,
    canConfirm: response.data.can_confirm,
    email: response.data.email,
    cognitoId: response.data.cognito_id,
  };
};

export const getPatientCodeConfirmationPreflight = async (
  code: string,
): Promise<ConfirmationStatus> => {
  const response =
    await patientApi.getPatientConfirmationStatusByOnboardingCodePatientConfirmCodeOnboardingCodeGet(
      code,
      {
        validateStatus: (status) => status === 200 || status === 404,
      },
    );

  if (response.status === 404) {
    return {
      tokenValid: false,
      canConfirm: false,
      email: null,
      cognitoId: null,
    };
  }

  return {
    tokenValid: true,
    canConfirm: response.data.can_confirm,
    email: response.data.email,
    cognitoId: response.data.cognito_id,
  };
};

export const confirmPatient = async (
  payload: ConfirmationPayload,
  jwt: string,
) => {
  const response = await patientApi.patientConfirmationPatientConfirmPost(
    payload,
    { headers: { Authorization: `Bearer ${jwt}` } },
  );

  return response.data;
};

export const confirmPatientByCode = async (
  payload: PatientConfirmationByCodePayload,
) => {
  const response =
    await patientApi.patientConfirmationByCodePatientConfirmCodePost(payload);

  return response.data;
};

export const getProviderConfirmationPreflight = async (
  jwt: string,
): Promise<ConfirmationStatus> => {
  const response =
    await providerApi.getProviderConfirmationStatusProviderConfirmGet({
      headers: { Authorization: `Bearer ${jwt}` },
      validateStatus: (status) =>
        status === 200 || status === 404 || status === 401,
    });

  if (response.status === 401 || response.status === 404) {
    return {
      tokenValid: false,
      canConfirm: false,
      email: null,
      cognitoId: null,
    };
  }

  return {
    tokenValid: true,
    canConfirm: response.data.can_confirm,
    email: response.data.email,
    cognitoId: response.data.cognito_id,
  };
};

export const confirmProvider = async (
  payload: ConfirmationPayload,
  jwt: string,
) => {
  const response = await providerApi.providerConfirmationProviderConfirmPost(
    payload,
    { headers: { Authorization: `Bearer ${jwt}` } },
  );

  return response.data;
};

export const getGoogleOAuth = async () => {
  const response =
    await providerApi.getGoogleCalendarOauthProvidersMeGoogleGet();

  if (response.status !== 200) {
    throw new Error(`Error getting Google OAuth State: ${response.statusText}`);
  }

  return response.data;
};

export const setGoogleOAuth = async (code: string) => {
  const response =
    await providerApi.setGoogleCalendarOauthProvidersMeGooglePost(code);

  if (response.status !== 200) {
    throw new Error(`Error setting Google OAuth: ${response.statusText}`);
  }

  return response.data;
};

export const destroyGoogleOAuth = async () => {
  const response =
    await providerApi.deleteGoogleCalendarOauthProvidersMeGoogleDelete();

  if (response.status !== 200) {
    throw new Error(`Error deleting Google OAuth: ${response.statusText}`);
  }

  return response.data;
};

export const updatePatientIntakeFormResponses = async (
  patientId: string,
  updateIntakePayload: UpdateIntakePayload,
) => {
  const response =
    await patientApi.setPatientIntakeFormPatientsPatientIdIntakePost(
      patientId,
      updateIntakePayload,
    );

  return response.data;
};

export const searchNpiProvidersDatabase = async ({
  name,
  limit,
  state,
  providerType,
}: {
  name: string;
  limit: number;
  state?: USState;
  providerType?: ExternalProviderType;
}) => {
  const response = await defaultApi.searchNpiDatabaseNpiGet(
    name,
    limit,
    state,
    providerType,
  );
  return response.data;
};

export const getMeetingUrlForAppointmentId = async (
  appointmentId: string,
  withDirectUrls: boolean = true,
) => {
  const response =
    await videoCallApi.getAppointmentVideoCallAppointmentsAppointmentIdVideoCallGet(
      appointmentId,
      withDirectUrls,
      { validateStatus: (status) => status === 200 || status === 404 },
    );

  if (response.status === 404) {
    return null;
  }

  return response.data;
};

export const addChartNoteScribeToAppointment = async (
  appointmentId: string,
): Promise<void> => {
  await videoCallApi.addRecordingBotToMeetingAppointmentsAppointmentIdRecordPost(
    appointmentId,
  );
};

export type AppointmentAddToCalendarDetails = Modify<
  AppointmentAddToCalendarDetailsResponse,
  { start: Date; end: Date }
>;

const _mapAppointmentAddToCalendarDetailsResponse = (
  appointment: AppointmentAddToCalendarDetailsResponse,
): AppointmentAddToCalendarDetails => ({
  ...appointment,
  start: convertDateTimeStringToDateTime(appointment.start),
  end: convertDateTimeStringToDateTime(appointment.end),
});

export const getAppointmentAddToCalendarDetails = async (
  appointmentId: string,
  jwt: string,
): Promise<AppointmentAddToCalendarDetails> => {
  const response =
    await appointmentApi.getAppointmentAddToCalendarDetailsAppointmentsAppointmentIdCalendarDetailsGet(
      appointmentId,
      { headers: { Authorization: `Bearer ${jwt}` } },
    );

  return _mapAppointmentAddToCalendarDetailsResponse(response.data);
};

export const getCommandsAndEvents = async () => {
  const response = await defaultApi.adminGetBusTypesAdminBusGet();
  return response.data;
};

export const resubmitCommandOrEventPayload = async (
  commandOrEvent: string,
  payloadData: Record<string, any>,
) => {
  const response =
    await defaultApi.adminResubmitEventOrCommandToBusAdminBusPost(
      {
        command_or_event_name: commandOrEvent,
        payload: payloadData,
      },
      {
        validateStatus: (status) => status === 200 || status === 400,
      },
    );

  return response.data;
};

export type PatientSignedReleaseForm = {
  contents: string;
  filename: string;
};

export const getPatientSignedReleaseForm = async (patientId: string) => {
  const response =
    await patientApi.getReleaseFormPatientsPatientIdReleaseFormPdfGet(
      patientId,
      { responseType: "arraybuffer" },
    );
  // the OpenAPI generator doesn't handle application/pdf response types well
  // so we have to manually cast this to an ArrayBuffer
  return response.data as unknown as ArrayBuffer;
};

export const updateReleaseOfInformation = async (
  patientId: string,
  payload: ReleaseOfInformationPayload,
) => {
  await patientApi.updateReleaseOfInformationPatientsPatientIdReleaseOfInformationPost(
    patientId,
    payload,
  );
};

export const getReleaseFormPreviewUrl = async () => {
  const response =
    await patientApi.getReleaseFormPreviewUrlPatientsFilesReleaseFormPreviewUrlGet();

  return response.data;
};

export const getProviderPracticeMetrics = async (providerId: string) => {
  const response =
    await providerApi.getProviderPracticeMetricsProvidersProviderIdPracticeMetricsGet(
      providerId,
      {
        validateStatus: (status) => status === 200 || status === 404,
        "axios-retry": {
          retries: 0,
        },
      },
    );

  // 404 is acceptable here, we just return null.
  if (response.status === 404) {
    return null;
  }

  return response.data;
};

export const getMyPayouts = async () => {
  const response = await payoutApi.getPayoutsForProviderPayoutsGet();
  return response.data;
};

export const getPatientDashboardDetails = async (token: string) => {
  const response =
    await patientApi.externalFacingDetailsPatientExternalFacingDetailsGet({
      headers: { Authorization: `Bearer ${token}` },
      validateStatus: (status) =>
        status === 200 || status === 401 || status === 404,
    });

  if (response.status === 200) {
    return response.data;
  } else if (response.status === 401) {
    // token is invalid
    throw new Error("The URL provided was invalid");
  } else if (response.status === 404) {
    // patient could not be found
    throw new Error("The patient could not be identified");
  } else {
    throw new Error("An unknown error occurred");
  }
};

export type JournalEntry = Modify<
  _JournalEntryResponse,
  {
    created_at: Date;
    occurred_at: Date;
    updated_at: Date | null;
    deleted_at: Date | null;
  }
>;

export type CursorPaginatedJournalEntriesResponse = Modify<
  _CursorPaginatedJournalEntriesResponse,
  { entries: JournalEntry[] }
>;

const mapJournalEntryResponse = (
  entry: _JournalEntryResponse,
): JournalEntry => ({
  ...entry,
  occurred_at: convertDateTimeStringToDateTime(entry.occurred_at),
  created_at: convertDateTimeStringToDateTime(entry.created_at),
  updated_at:
    entry.updated_at !== null
      ? convertDateTimeStringToDateTime(entry.updated_at)
      : null,
  deleted_at:
    entry.deleted_at !== null
      ? convertDateTimeStringToDateTime(entry.deleted_at)
      : null,
});

const mapCursorPaginatedJournalEntriesResponse = (
  response: _CursorPaginatedJournalEntriesResponse,
): CursorPaginatedJournalEntriesResponse => ({
  ...response,
  entries: response.entries.map(mapJournalEntryResponse),
});

export const getJournalEntries = async (
  patientId: string,
  cursor: string | null,
): Promise<CursorPaginatedJournalEntriesResponse> => {
  const { data } =
    await patientApi.getPatientJournalEntriesPatientPatientIdJournalEntriesGet(
      patientId,
      cursor,
    );

  return mapCursorPaginatedJournalEntriesResponse(data);
};

export const getJournalEntry = async (id: string): Promise<JournalEntry> => {
  const { data } =
    await patientApi.getJournalEntryPatientJournalEntriesJournalEntryIdGet(id);
  return mapJournalEntryResponse(data);
};

export const createJournalEntry = async (
  payload: CreateJournalEntryPayload,
): Promise<JournalEntry> => {
  // timezone is automatically provided!
  const current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data } = await patientApi.createJournalEntryPatientJournalEntriesPost(
    {
      current_timezone,
      ...payload,
    },
  );
  return mapJournalEntryResponse(data);
};

export const updateJournalEntry = async ({
  id,
  payload,
}: {
  id: string;
  payload: UpdateJournalEntryPayload;
}): Promise<JournalEntry> => {
  // timezone is automatically provided!
  const current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } =
    await patientApi.updateJournalEntryPatientJournalEntriesJournalEntryIdPatch(
      id,
      {
        current_timezone,
        ...payload,
      },
    );
  return mapJournalEntryResponse(data);
};

export const deleteJournalEntry = async (id: string): Promise<void> => {
  await patientApi.deleteJournalEntryPatientJournalEntriesJournalEntryIdDelete(
    id,
  );
};

export const getJournalStreak = async (): Promise<JournalStreakResponse> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data } =
    await patientApi.getJournalStreakPatientJournalStreakGet(timezone);

  return data;
};

export const uploadPatientLabReport = async (
  patientId: string,
  payload: UploadPatientLabPayload,
): Promise<void> => {
  await patientApi.uploadPatientLabPatientPatientIdLabsPost(patientId, payload);
};

export const getPatientLabs = async (
  patientId: string,
): Promise<PatientLabsResponse> => {
  const { data } =
    await patientApi.getPatientLabsPatientPatientIdLabsGet(patientId);

  return data;
};

export const getPatientLab = async (
  patientId: string,
  labId: string,
): Promise<PatientLabResponse> => {
  const { data } = await patientApi.getPatientLabPatientPatientIdLabsLabIdGet(
    labId,
    patientId,
  );

  return data;
};

export const postPatientLabFeedback = async ({
  patientId,
  labId,
  isFeedbackPositive,
}: {
  patientId: string;
  labId: string;
  isFeedbackPositive: boolean;
}): Promise<PatientLabResponse> => {
  const { data } =
    await patientApi.addPatientLabFeedbackPatientPatientIdLabsLabIdFeedbackPost(
      labId,
      patientId,
      { is_feedback_positive: isFeedbackPositive },
    );
  return data;
};

export const sendNutritionResearchMessage = async (
  threadId: string,
  message: string,
) => {
  await nutritionResearchApi.sendNutritionResearchMessageNutritionResearchMessagePost(
    { thread_id: threadId, message },
  );
};

export const runNutritionResearchThread = async (
  threadId: string,
): Promise<NutritionResearchRunThreadResponse> => {
  const { data } =
    await nutritionResearchApi.runNutritionResearchThreadNutritionResearchThreadsRunThreadIdPost(
      threadId,
    );
  return data;
};

export const streamNutritionResearchThread = async (
  threadId: string,
): Promise<ReadableStream<Uint8Array>> => {
  const endpointURL = `${process.env.REACT_APP_FAY_API_BASE_PATH}/nutrition-research/threads/stream/${threadId}`;
  const accessToken =
    typeof configuration.accessToken === "function"
      ? await configuration.accessToken()
      : configuration.accessToken;

  const response = await fetch(endpointURL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "text/event-stream",
    },
  });
  const stream = response.body;
  if (!stream) {
    throw new Error(
      "No stream returned when running nutrition research thread",
    );
  }
  return stream;
};

export const createNutritionResearchThread =
  async (): Promise<CreateNutritionResearchThreadResponse> => {
    const { data } =
      await nutritionResearchApi.createNutritionResearchThreadNutritionResearchThreadsPost();
    return data;
  };

export const getNutritionResearchThread =
  async (): Promise<GetNutritionResearchThreadResponse | null> => {
    const response =
      await nutritionResearchApi.getNutritionResearchThreadNutritionResearchThreadsGet(
        {
          validateStatus: (status) => status === 200 || status === 404,
          "axios-retry": {
            retries: 0,
          },
        },
      );

    if (response.status === 404) {
      return null;
    }

    return response.data;
  };

export const getNutritionResearchThreadMessages = async (
  threadId: string,
  pagination_options: { page_size: number; offset: number },
): Promise<GetNutritionResearchThreadMessagesResponse> => {
  const { data } =
    await nutritionResearchApi.getNutritionResearchThreadMessagesNutritionResearchThreadsThreadIdMessagesGet(
      threadId,
      pagination_options.page_size,
      pagination_options.offset,
    );
  return data;
};

interface GetOrCreateNutritionResearchThreadResponse {
  threadId: string;
  messages: NutritionResearchMessageDetails[];
}

// we only support 1 thread per provider at the moment
export const getOrCreateNutritionResearchThread = async (
  providerId: string,
  pagination_options: { page_size: number; offset: number },
): Promise<GetOrCreateNutritionResearchThreadResponse> => {
  const getThreadResponse = await getNutritionResearchThread();
  let threadId = getThreadResponse?.thread_id;
  if (!threadId) {
    const createResponse = await createNutritionResearchThread();
    threadId = createResponse.thread_id;
  }

  const messageReponse = await getNutritionResearchThreadMessages(
    threadId,
    pagination_options,
  );
  return { threadId, messages: messageReponse.messages };
};

export const getPatientRecap = async (
  patientId: string,
): Promise<PatientRecap> => {
  const { data } =
    await patientRecapApi.getPatientRecapPatientRecapPatientIdGet(patientId);
  return data;
};

export const draftProviderChatResponse = async (patient_id: string) => {
  const { data } = await chatApi.suggestChatResponseChatSuggestionPost({
    patient_id,
  });

  return { ...data, patient_id };
};

export const getMealPlanDetails = async (
  mealPlanId: string,
): Promise<MealPlan> => {
  const response =
    await mealPlanApi.getMealPlanMealPlanMealPlanIdGet(mealPlanId);
  return response.data;
};

export const initializeMealPlan = async (
  patientId: string,
  description: string,
  conditions: MealPlanCondition[],
  calorieTarget: number,
): Promise<MealPlan> => {
  const response = await mealPlanApi.generateMealPlanMealPlanPost({
    patient_id: patientId,
    description,
    conditions,
    calorie_target: calorieTarget,
  });

  return response.data;
};

export const updateMealPlanMeal = async (
  mealPlanId: string,
  mealId: string,
  payload: UpdateMealPayload,
): Promise<MealPlan> => {
  const response = await mealPlanApi.updateMealMealPlanMealPlanIdMealsMealIdPut(
    mealPlanId,
    mealId,
    payload,
  );

  return response.data;
};

export const sendMealPlan = async (mealPlanId: string): Promise<MealPlan> => {
  return (await mealPlanApi.sendMealPlanMealPlanMealPlanIdSendPost(mealPlanId))
    .data;
};

export const provideFeedbackOnResearchAssistantMessage = async ({
  messageId,
  feedbackRating,
}: {
  messageId: string;
  feedbackRating: NutritionResearchFeedbackRating;
}) => {
  await nutritionResearchApi.nutritionResearchFeedbackNutritionResearchFeedbackPost(
    {
      message_id: messageId,
      feedback_rating: feedbackRating,
    },
  );
};

export const provideFeedbackOnChatCompletion = async ({
  chatCompletionId,
  binaryFeedback,
  feedbackText,
}: {
  chatCompletionId: string;
  binaryFeedback?: boolean;
  feedbackText?: string;
}) => {
  await aiFeedbackApi.updateChatCompletionFeedbackAiFeedbackChatCompletionChatCompletionIdPut(
    chatCompletionId,
    {
      binary_feedback: binaryFeedback,
      feedback_text: feedbackText,
    },
  );
};

export const offboardProvider = async ({
  providerId,
}: {
  providerId: string;
}) => {
  return await providerApi.offboardProviderAdminProvidersProviderIdOffboardPost(
    providerId,
  );
};

export const finalizePayouts = async () => {
  return await payoutApi.finalizePayoutsAdminPayoutsFinalizePost();
};

export const recordClientReferralImpression = async (linkUrl: string) => {
  const response =
    await providerApi.recordClientReferralImpressionProvidersClientReferralsImpressionPost(
      {
        link_url: linkUrl,
      },
    );

  return response.data.token;
};

export const getClientReferralDetails = async (providerId: string) => {
  const response =
    await providerApi.getProviderClientReferralsProvidersProviderIdClientReferralsGet(
      providerId,
    );

  return response.data;
};

export const sendClientReferral = async (
  providerId: string,
  phoneNumber: string,
) => {
  const response =
    await providerApi.sendProviderClientReferralSmsProvidersProviderIdClientReferralsSmsPost(
      providerId,
      {
        phone_number: phoneNumber,
      },
    );

  return response.data;
};

export const getReputation = async (providerId: string) => {
  const response =
    await providerApi.getProviderReputationProvidersProviderIdReputationGet(
      providerId,
    );

  return response.data;
};

export const getProviderRates = async (providerId: string) => {
  const response =
    await providerApi.getProviderRatesProvidersProviderIdRatesGet(providerId);

  return response.data;
};

export const submitProviderReview = async (
  providerId: string,
  reviewId: string,
  payload: ProviderFeedbackPayload,
) => {
  const response =
    await providerApi.updateProviderFeedbackProvidersProviderIdReviewReviewIdPut(
      providerId,
      reviewId,
      payload,
    );
  return response.data;
};

export const getBillingItems = async (
  providerId: string,
): Promise<Array<PatientBillingItemDetailsResponse>> => {
  const response =
    await patientBillingApi.getPatientBillingItemsBillingPatientIdBillingItemsGet(
      providerId,
    );

  return response.data;
};

export const refundPatientCharge = async (
  chargeId: string,
  patientId: string,
) => {
  await patientBillingApi.refundPatientChargeBillingPatientIdChargeChargeIdRefundPost(
    chargeId,
    patientId,
  );
};

export const getProviderReviewLinkForSlug = async (
  providerSlug: string,
): Promise<string | null> => {
  const response =
    await providerApi.getGoogleBusinessReviewLinkProvidersProviderSlugGoogleBusinessReviewLinkGet(
      providerSlug,
      {
        validateStatus: (status) => status === 200 || status === 404,
      },
    );

  if (response.status === 404) {
    return null;
  }

  return response.data.review_link;
};

export const solicitProviderReview = async (
  providerId: string,
  messageContent: string,
  patientId?: string,
  otherPhoneNumber?: string,
) => {
  // Must have one and only one of patientId or otherPhoneNumber.
  if (!patientId && !otherPhoneNumber) {
    throw new Error("Must provide either patientId or otherPhoneNumber");
  }

  if (patientId && otherPhoneNumber) {
    throw new Error("Cannot provide both patientId and otherPhoneNumber");
  }

  const response =
    await providerApi.solicitProviderReviewProvidersProviderIdReviewSolicitationPost(
      providerId,
      {
        patient_id: patientId,
        phone_number: otherPhoneNumber,
        message_content: messageContent,
      },
    );

  return response.data; // Will be just {} if successful
};

export const getProviderAvailabilitySlots = async (
  providerId: string,
  start: Date,
  end: Date,
  impressionToken?: string,
) => {
  const response =
    await providerApi.getProviderAvailableSlotsProvidersProviderIdAvailabilitySlotsGet(
      providerId,
      start.toISOString(),
      end.toISOString(),
      impressionToken,
    );

  return response.data;
};

export const getBookingInfo = async (bookingCode: string) => {
  const response =
    await partnerBookingApi.getPartnerBookingInfoPartnersBookingBookingCodeGet(
      bookingCode,
    );
  return response.data;
};

export const completePartnerBooking = async (
  bookingCode: string,
  payload: CompletePartnerBookingPayload,
) => {
  const response =
    await partnerBookingApi.completePartnerBookingPartnersBookingBookingCodePost(
      bookingCode,
      payload,
    );
  return response.data;
};

export type LoginResponse = Modify<
  _LoginResponse,
  {
    cooldown_expires_at: Date;
    expires_at: Date;
  }
>;

const _map_login_response = (response: _LoginResponse): LoginResponse => ({
  ...response,
  cooldown_expires_at: convertDateTimeStringToDateTime(
    response.cooldown_expires_at,
  ),
  expires_at: convertDateTimeStringToDateTime(response.expires_at),
});

export const createPhoneLoginChallenge = async (
  userType: "patient" | "provider",
  phoneNumber: string,
) => {
  const response = await authApi.loginAuthLoginPost({
    user_type: userType,
    phone_number: phoneNumber,
  });

  return _map_login_response(response.data);
};

export const completePhoneLoginChallenge = async (
  challengeId: string,
  code: string,
) => {
  const response = await authApi.completeLoginAuthLoginCompletePost({
    code,
    challenge_id: challengeId,
  });

  return _map_login_response(response.data);
};

export type UpdateIdentityResponse = Modify<
  _UpdateIdentityResponse,
  {
    cooldown_expires_at: Date;
    expires_at: Date;
  }
>;

const _map_update_identity_response = (
  response: _UpdateIdentityResponse,
): UpdateIdentityResponse => ({
  ...response,
  cooldown_expires_at: convertDateTimeStringToDateTime(
    response.cooldown_expires_at,
  ),
  expires_at: convertDateTimeStringToDateTime(response.expires_at),
});

export const updateIdentity = async (email?: string, phone_number?: string) => {
  const response = await authApi.beginUpdateIdentityAuthUpdateIdentityPost({
    email: email || null,
    phone_number: phone_number || null,
  });
  return _map_update_identity_response(response.data);
};

export const completeUpdateIdentity = async (
  challengeId: string,
  code: string,
) => {
  const response =
    await authApi.completeUpdateIdentityAuthUpdateIdentityCompletePost({
      challenge_id: challengeId,
      code,
    });
  return response.data;
};

export type ResetPasswordResponse = Modify<
  _ResetPasswordResponse,
  {
    cooldown_expires_at: Date;
    expires_at: Date;
  }
>;

const _map_reset_password_response = (
  response: _ResetPasswordResponse,
): ResetPasswordResponse => ({
  ...response,
  cooldown_expires_at: convertDateTimeStringToDateTime(
    response.cooldown_expires_at,
  ),
  expires_at: convertDateTimeStringToDateTime(response.expires_at),
});

export const beginResetPassword = async (
  userType: "patient" | "provider",
  email: string,
) => {
  const response = await authApi.resetPasswordAuthResetPasswordPost({
    email,
    user_type: userType,
  });

  return _map_reset_password_response(response.data);
};

export const completeResetPassword = async (
  token: string,
  newPassword: string,
) => {
  const response =
    await authApi.completeResetPasswordAuthResetPasswordCompletePost({
      token,
      new_password: newPassword,
    });

  return _map_reset_password_response(response.data);
};
